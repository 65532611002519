import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import isEmpty from 'lodash/isEmpty'
import { useIsSmallerThanPayment } from 'src/hooks/useIsSmallerThanPayment'
import { themeColors } from 'training-lane-booking-shared/utilities/constants'
import { getYearMonthDate } from 'training-lane-booking-shared/utilities/functions'
import PaymentProcessorModal from '../../Modals/PaymentProcessorModal/PaymentProcessorModal'
import textConstants from 'training-lane-booking-shared/utilities/text'

const stripePromise = loadStripe(
    process.env.STRIPE_API_URL,
    { betas: ["elements_saved_payment_methods_beta_1"] }
)

const PaymentProcessorWrapper = () => {
    const firebase = useFirebase()
    const [clientSecretLocal, setClientSecretLocal] = useState('')
    const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState('')

    const now = useSelector(state => state.utils.now)
    const email = useSelector(state => state.firebase.auth.email)
    const displayName = useSelector(state => state.firebase.auth.displayName)
    const selectedItem = useSelector(state => state.payments.selectedItem)

    const dispatch = useDispatch()

    const isSmall = useIsSmallerThanPayment()

    useEffect(() => {
        const { itemType, bundle } = selectedItem

        if (
            !isEmpty(itemType) &&
            itemType.id &&
            bundle.key
        ) {
            const getPaymentIntent = async () => {
                return await dispatch.payments.createPaymentIntent({
                    payload: {
                        type: itemType.id,
                        bundle: bundle.key,
                        email,
                        displayName,
                        date: getYearMonthDate(now)
                    },
                    firebase
                })
            }
            getPaymentIntent().then(data => {
                setClientSecretLocal(data.data.clientSecret)
                setCustomerSessionClientSecret(data.data.customerSessionClientSecret)
            })
        }
    }, [selectedItem, email, displayName, firebase, now, dispatch.payments])

    let stripeAppearanceVariables = {
        colorPrimary: '#000000',
        colorBackground: '#ffffff',
        colorDanger: themeColors.brandRed,
        colorIconCardError: themeColors.brandRed,
        borderRadius: '0',
        fontFamily: 'Poppins, sans-serif',
        fontSmooth: 'always',
        fontSizeBase: 16
    }

    if (isSmall) {
        stripeAppearanceVariables.spacingUnit = '2px'
    }

    const defaultSuccessMessage = textConstants.PURCHASE_SUCCESS

    const altSuccessMessage = (
        <>
            <div>{defaultSuccessMessage}.</div>
            <div>{textConstants.PURCHASE_SUCCESS_OTHER_TYPE}.</div>
        </>
    )

    const paymentProcessorModalProps = {
        defaultSuccessMessage,
        altSuccessMessage
    }

    // noinspection JSValidateTypes
    return clientSecretLocal.length ? (
        <Elements stripe={stripePromise} options={{
            clientSecret: clientSecretLocal,
            customerSessionClientSecret,
            fonts: [{
                cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:300,300italic,400,400italic'
            }],
            appearance: {
                theme: 'stripe',
                labels: 'floating',
                variables: { ...stripeAppearanceVariables }
            }
        }}>
            <PaymentProcessorModal {...paymentProcessorModalProps} />
        </Elements>
    ) : null
}

export default PaymentProcessorWrapper
