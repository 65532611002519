import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useFirebase } from 'react-redux-firebase'
import { useFirebaseFunctions } from 'src/hooks/useFirebaseFunctions'
import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import cloneDeep from 'lodash/cloneDeep'
import { useIsSmallerThanPayment } from 'src/hooks/useIsSmallerThanPayment'
import AnimatedContainer from 'src/containers/AnimatedContainer/AnimatedContainer'
import Modal from 'src/components/UI/Modal/Modal'
import PurchasePickedTitle from 'src/components/Purchase/PurchasePickedTitle/PurchasePickedTitle'
import PurchasePickedInfo from 'src/components/Purchase/PurchasePickedInfo/PurchasePickedInfo'
import Strikethrough from 'src/components/UI/Strikethrough/Strikethrough'
import Confirm from 'src/components/UI/Confirm/Confirm'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import Spinner from 'src/components/UI/Spinner/Spinner'
import PurchasePickedInfoShared from 'training-lane-booking-shared/components/Purchase/PurchasePickedInfoShared/PurchasePickedInfoShared'
import { getYearMonthDate, isNonRefundableSlot, prepareSlot } from 'training-lane-booking-shared/utilities/functions'
import { defaultCountryCode } from 'training-lane-booking-shared/utilities/constants'
import textConstants from 'training-lane-booking-shared/utilities/text'
import paymentProcessorModalClasses from './PaymentProcessorModal.module.scss'

const PaymentProcessorModal = ({
    defaultSuccessMessage,
    altSuccessMessage,
    uid,
    email,
    displayName,
    credits,
    selectedItem,
    lastClickedSlot,
    now,
    modalStatePurchase,
    createPaymentIntent,
    addSlotAsync,
    triggerSlotsLoading,
    logPayment,
    setModalState,
    log
}) => {
    const firebase = useFirebase()
    useFirebaseFunctions()
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [succeeded, setSucceeded] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [clientSecret, setClientSecret] = useState('')
    const [payment, setPayment] = useState(null)
    const [successText, setSuccessText] = useState(defaultSuccessMessage)
    const [showPickedInfo, setShowPickedInfo] = useState(false)
    const pickedInfoStartEndHeight = [0, 'auto']
    let pickedInfoStartEndHeightReversed = [ ...pickedInfoStartEndHeight ]
    pickedInfoStartEndHeightReversed.reverse()

    const clearState = () => {
        setMessage(null)
        setProcessing(false)
        setSucceeded(false)
        setDisabled(true)
        setPayment(null)
        setClientSecret('')
    }    

    const isSmall = useIsSmallerThanPayment()

    useEffect(() => {
        const { itemType, bundle } = selectedItem

        if (
            !isEmpty(itemType) &&
            itemType.id &&
            bundle.key
        ) {
            const getPaymentIntent = async () => {
                return await createPaymentIntent({
                    payload: {
                        type: itemType.id,
                        bundle: bundle.key,
                        email,
                        displayName,
                        date: getYearMonthDate(now)
                    },
                    firebase
                })
            }
            getPaymentIntent().then(data => {
                setClientSecret(data.data.clientSecret)
            })
        }
    }, [createPaymentIntent, selectedItem, email, displayName, firebase, now])

    useEffect(() => {
        setShowPickedInfo(true)
    }, [])

    const togglePickedInfoState = boolean => {
        if (isSmall) {
            setShowPickedInfo(pis => boolean ?? !pis)
        }
    }

    const handleChange = paymentProp => {
        const paymentElement = elements.getElement('payment')

        if (paymentElement) {
            paymentElement.on('change', () => {
                togglePickedInfoState(false)
            })
        }
        
        setDisabled(
            (paymentProp.empty === true && !['google_pay', 'apple_pay', 'affirm'].includes(paymentProp.value.type))
            || paymentProp.complete === false
        )
        
        setMessage(paymentProp.error?.message ?? null)
        
        if (paymentProp.complete || ['google_pay', 'apple_pay'].includes(paymentProp.value.type)) {
            setPayment(paymentProp)
        }
    }

    const handleAbort = () => {
        clearState()
        setModalState('paymentProcessor', { state: false })
    }

    const handlePayConfirm = async () => {
        setProcessing(true)

        if (!payment) {
            setProcessing(false)
            return
        }

        const submitPayment = async () => await elements.submit()

        const { error: submitError } = await submitPayment(clientSecret)

        if (submitError) {
            setMessage(
                `${textConstants.PURCHASE_ERROR}! ${textConstants.PURCHASE_ERROR_INSTRUCTIONS}`
            )
            setProcessing(false)
            log({ message: 'Error in submit', submitError, firebase })
        } else {
            setMessage(null)

            // noinspection JSUnresolvedReference
            const { error } = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: process.env.SITE_URL
                },
                redirect: "if_required"
            })

            if (error) {
                setMessage(
                    `${textConstants.PURCHASE_ERROR}! ${textConstants.PURCHASE_ERROR_INSTRUCTIONS}`
                )
                setProcessing(false)
                log({ message: 'Error in confirmPayment', error, firebase })
            } else {
                setMessage(null)

                const { total, itemType, bundle } = selectedItem

                const { updatedCredits } = await logPayment({
                    owner: uid,
                    oldCredits: { credits },
                    newCredits: { [itemType.id]: bundle.key },
                    total,
                    paymentType: 'member-purchase',
                    firebase
                })
                // noinspection JSUnresolvedVariable
                if ((!has(modalStatePurchase.props, 'calledBy') || modalStatePurchase.props.calledBy !== 'menu') && itemType.id === lastClickedSlot.type) {
                    const { date, lane, time, type } = lastClickedSlot
                    const newSlot = {
                        ...prepareSlot(uid, date, lane, time, type, true)
                    }
                    if (isNonRefundableSlot(now, newSlot)) {
                        setProcessing(false)
                        setModalState('paymentProcessor', { state: false })
                        setModalState('slotPickerConfirm', { state: true })
                        setTimeout(() => setModalState('purchase', { state: false }), 100)
                    } else {
                        newSlot.action = 'add-slot'
                        newSlot.ownerData = cloneDeep(updatedCredits)

                        triggerSlotsLoading(true)

                        await addSlotAsync(newSlot, firebase).then(() => {
                            setProcessing(false)
                            setModalState('paymentProcessor', { state: false })
                            setModalState('slotPicker', { state: true })
                            setTimeout(() => setModalState('purchase', { state: false }), 100)
                        })
                    }
                } else {
                    setSuccessText(altSuccessMessage)
                    setProcessing(false)
                }

                setSucceeded(true)
            }
        }
    }

    const confirmButtons = {
        abort: {
            handleFn: handleAbort,
            label: textConstants.ABORT,
            mode: 'text'
        },
        pay: {
            handleFn:
                processing || disabled || succeeded ? () => {} : handlePayConfirm,
            loading: processing,
            takeEnter: true,
            disabled: processing || disabled || succeeded
        },
        proceed: {
            handleFn: () => {
                setModalState('paymentProcessor', {
                    state: false,
                    props: {
                        triggerPostClose: true
                    }
                })
                clearState()
                setModalState('purchase', { state: false })
            },
            label: textConstants.PROCEED,
            loading: processing,
            disabled: processing || disabled
        },
        buyMore: {
            handleFn: () => {
                clearState()
                setModalState('paymentProcessor', { state: false })
            },
            label: textConstants.BUY_MORE,
            mode: 'text'
        }
    }

    const stripePaymentOptions = {
        business: {
            name: 'Test Me'
        },
        defaultValues: {
            billingDetails: {
                name: 'Full Name',
                email,
                phone: '7182222222',
                address: {
                    country: defaultCountryCode,
                    postal_code: 'M5A 1L9',
                    state: 'Ontario',
                    city: 'Toronto',
                    line1: '487 King Street East'
                }
            }
        }
    }
    
    const handleClose = () => {
        setShowPickedInfo(true)
        
        handleAbort()
    }

    confirmButtons.abort.buttonClasses = confirmClasses.confirmButtonAbort
    confirmButtons.pay.buttonClasses = confirmClasses.confirmButtonProceedDanger
    confirmButtons.pay.label = processing ? (
        <Spinner />
    ) : (
        textConstants.PURCHASE_BUTTON_PAY
    )
    confirmButtons.proceed.buttonClasses = confirmClasses.confirmButtonProceedSafe
    confirmButtons.buyMore.buttonClasses = confirmClasses.confirmButtonAbort

    return (
        <Modal name="paymentProcessor" handleClose={handleClose} nested>
            <Confirm
                newLineAboveButtons={false}
                heading={
                    <>
                        <AnimatedContainer
                            animatedContainerId="payment-processor-top"
                            containerClassName="payment-processor-top"
                            startEndHeight={
                                showPickedInfo
                                    ? pickedInfoStartEndHeight
                                    : pickedInfoStartEndHeightReversed
                            }>
                            <div>{textConstants.PURCHASE_CONFIRM_HEADING}</div>
                            <br />
                            <PurchasePickedTitle itemType={selectedItem.itemType} />
                            <br />
                            <PurchasePickedInfoShared
                                PurchasePickedInfo={PurchasePickedInfo}
                                Strikethrough={Strikethrough}
                                bundle={selectedItem.bundle}
                                total={selectedItem.total}
                                typeId={selectedItem.itemType.id}
                            />
                            <br />
                        </AnimatedContainer>
                        <AnimatedContainer
                            animatedContainerId="payment-processor-bottom"
                            containerClassName="payment-processor-bottom"
                            startEndHeight={
                                showPickedInfo
                                    ? pickedInfoStartEndHeightReversed
                                    : pickedInfoStartEndHeight
                            }>
                            <PurchasePickedInfoShared
                                compact
                                PurchasePickedInfo={PurchasePickedInfo}
                                Strikethrough={Strikethrough}
                                bundle={selectedItem.bundle}
                                total={selectedItem.total}
                                typeId={selectedItem.itemType.id}
                                itemTypeColor={selectedItem.itemType.color}
                            />
                        </AnimatedContainer>
                        <div style={{ display: succeeded ? 'none' : 'block'}}>
                            <PaymentElement
                                className={paymentProcessorModalClasses.cardElement}
                                options={stripePaymentOptions}
                                onChange={handleChange}
                            />
                        </div>
                        {message && (
                            <>
                                <br />
                                <div
                                    className={paymentProcessorModalClasses.cardError}
                                    role="alert">
                                    {message}
                                </div>
                            </>
                        )}
                        {succeeded && (
                            <>
                                <br />
                                <div className={paymentProcessorModalClasses.resultMessage}>
                                    {successText}
                                </div>
                            </>
                        )}
                    </>
                }
                buttons={
                    succeeded &&
                    (has(modalStatePurchase.props, 'calledBy') && modalStatePurchase.props.calledBy === 'menu' || selectedItem.itemType.id !== lastClickedSlot.type)
                        ? [confirmButtons.buyMore, confirmButtons.proceed]
                        : [confirmButtons.abort, confirmButtons.pay]
                }
            />
        </Modal>
    )
}



// noinspection JSUnresolvedVariable
const mapState = state => ({
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    displayName: state.firebase.auth.displayName,
    credits: state.firebase.profile.credits,
    selectedItem: state.payments.selectedItem,
    lastClickedSlot: state.slotPicker.lastClickedSlot,
    now: state.utils.now,
    modalStatePurchase: state.utils.modalState.purchase
})

const mapDispatch = dispatch => ({
    createPaymentIntent: payload =>
        dispatch.payments.createPaymentIntent(payload),
    addSlotAsync: (slot, firebase) =>
        dispatch.slotPicker.addSlotAsync({ slot, firebase }),
    triggerSlotsLoading: boolean =>
        dispatch.slotPicker.triggerSlotsLoading(boolean),
    logPayment: payload => dispatch.payments.logPayment(payload),
    setModalState: (modalName, payload) =>
        dispatch.utils.setModalState(modalName, payload),
    log: payload => dispatch.utils.log(payload)
})

export default connect(mapState, mapDispatch)(PaymentProcessorModal)
